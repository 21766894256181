import { Injectable } from '@angular/core';
import {
    AngularFirestore,
    DocumentChangeAction,
} from '@angular/fire/compat/firestore';
import { Exercise } from 'app/models/exercise';
import { ExerciseLog, SessionLog } from 'app/models/exerciseSessionLog';
import { map, Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ExerciseLogService {
    constructor(private firestore: AngularFirestore) {}

    selectedExercise: BehaviorSubject<Exercise> = new BehaviorSubject(null);
    workoutSelected: BehaviorSubject<Date> = new BehaviorSubject(null);

    async createTrainingSessionLog(
        coachId: string,
        clientId: string,
        dateSelected: Date,
        session: SessionLog,
        defaultExercises?: ExerciseLog[]
    ) {
        const batch = this.firestore.firestore.batch();

        const ref = this.firestore
            .collection('users')
            .doc(coachId)
            .collection('users')
            .doc(clientId)
            .collection('training-sessions')
            .doc(this.formatDatetoStartOfDayString(dateSelected)).ref;

        batch.set(ref, session);

        if (defaultExercises?.length > 0) {
            for (let exercise of defaultExercises) {
                const exerciseLogRef = ref
                    .collection('exercise-log')
                    .doc(exercise.id);
                batch.set(exerciseLogRef, exercise);
            }
        }

        await batch.commit();

        return ref;
    }

    getLastSessionByExercise(
        clientId: string,
        exerciseId: string,
        limit: number = 10
    ) {
        return this.firestore
            .collectionGroup('exercise-log', (ref) =>
                ref
                    .where('clientId', '==', clientId)
                    .where('exerciseId', '==', exerciseId)
                    .orderBy('logged', 'desc')
                    .limit(limit)
            )
            .snapshotChanges()
            .pipe(
                map((actions: DocumentChangeAction<ExerciseLog>[]) => {
                    return actions.map(
                        (action: DocumentChangeAction<ExerciseLog>) => {
                            const data: any = action.payload.doc.data();
                            const id: string = action.payload.doc.ref.id;
                            return this.mapToExerciseLog(data, id);
                        }
                    );
                })
            );
    }

    mapToExerciseLog(data: any, id: string) {
        const logged = data.logged?.toDate() ?? null;

        let loggedExercise = data.loggedExercise ?? null;

        if (loggedExercise) {
            loggedExercise.notes = data.notes ?? null;

            if (!loggedExercise.id || !loggedExercise.exerciseId) {
                loggedExercise.id = id;
                loggedExercise.exerciseId = data.exerciseId ?? null;
            }
        }

        let coachProgrammedExercise = data.coachProgrammedExercise ?? null;
        //Check if in old format.
        if ((data.sets || data.name) && !loggedExercise) {
            loggedExercise = {
                name: data.name,
                sets: data.sets,
                notes: data.notes,
                exerciseId: data.exerciseId,
            };

            if (!coachProgrammedExercise) {
                coachProgrammedExercise = loggedExercise;
                coachProgrammedExercise.notes = null;
            }
        }

        return {
            id,
            ...data,
            logged,
            loggedExercise,
            coachProgrammedExercise,
        } as ExerciseLog;
    }

    getExerciseLog(
        coachId: string,
        clientId: string,
        dateSelected: Date,
        exerciseId: string
    ): Observable<ExerciseLog> {
        return this.firestore
            .collection('users')
            .doc(coachId)
            .collection('users')
            .doc(clientId)
            .collection('training-sessions')
            .doc(this.formatDatetoStartOfDayString(dateSelected))
            .collection('exercise-log')
            .doc(exerciseId)
            .snapshotChanges()
            .pipe(
                map(
                    (a: any) => {
                        const data: any = a.payload.data();

                        if (!data) {
                            return null;
                        }
                        const id: string = a.payload.ref.id;

                        return this.mapToExerciseLog(data, id);
                    },
                    (error) => {
                        console.log('An error occured: ', error);
                    }
                )
            );
    }

    updateTrainingSessionLog(
        coachId: string,
        clientId: string,
        session: SessionLog,
        dateSelected: Date
    ) {
        return this.firestore
            .collection('users')
            .doc(coachId)
            .collection('users')
            .doc(clientId)
            .collection('training-sessions')
            .doc(this.formatDatetoStartOfDayString(dateSelected))
            .set(session, { merge: true });
    }

    getSession(
        coachId: string,
        clientId: string,
        date: Date
    ): Observable<SessionLog> {
        return this.firestore
            .collection('users')
            .doc(coachId)
            .collection('users')
            .doc(clientId)
            .collection('training-sessions')
            .doc<SessionLog>(this.formatDatetoStartOfDayString(date))
            .snapshotChanges()
            .pipe(
                map(
                    (a: any) => {
                        const data: any = a.payload.data();

                        if (!data) {
                            return null;
                        }
                        const id: string = a.payload.ref.id;
                        const started = data?.started?.toDate() ?? null;
                        const ended = data?.ended?.toDate() ?? null;

                        return {
                            ...data,
                            started,
                            ended,
                            id,
                        } as SessionLog;
                    },
                    (error) => {
                        console.log('An error occured: ', error);
                    }
                )
            );
    }

    getExercises(
        coachId: string,
        clientId: string,
        dateSelected: Date
    ): Observable<ExerciseLog[]> {
        return this.firestore
            .collection('users')
            .doc(coachId)
            .collection('users')
            .doc(clientId)
            .collection('training-sessions')
            .doc(this.formatDatetoStartOfDayString(dateSelected))
            .collection('exercise-log', (ref) => ref.orderBy('logged', 'asc'))
            .snapshotChanges()
            .pipe(
                map((actions: DocumentChangeAction<ExerciseLog>[]) => {
                    return actions.map(
                        (action: DocumentChangeAction<ExerciseLog>) => {
                            const data: any = action.payload.doc.data();
                            const id: string = action.payload.doc.ref.id;

                            return this.mapToExerciseLog(data, id);
                        }
                    );
                })
            );
    }

    updateExerciseLog(
        exerciseLogId: string,
        coachId: string,
        clientId: string,
        dateSelected: Date,
        exerciseLog: ExerciseLog
    ) {
        const ref = exerciseLogId
            ? this.firestore
                  .collection('users')
                  .doc(coachId)
                  .collection('users')
                  .doc(clientId)
                  .collection('training-sessions')
                  .doc(this.formatDatetoStartOfDayString(dateSelected))
                  .collection('exercise-log')
                  .doc(exerciseLog.id)
            : this.firestore
                  .collection('users')
                  .doc(coachId)
                  .collection('users')
                  .doc(clientId)
                  .collection('training-sessions')
                  .doc(this.formatDatetoStartOfDayString(dateSelected))
                  .collection('exercise-log')
                  .doc();

        exerciseLog.id = ref.ref.id;

        return ref.set(exerciseLog, { merge: true });
    }

    deleteExercise(
        coachId: string,
        clientId: string,
        dateSelected: Date,
        exerciseId: string
    ) {
        return this.firestore
            .collection('users')
            .doc(coachId)
            .collection('users')
            .doc(clientId)
            .collection('training-sessions')
            .doc(this.formatDatetoStartOfDayString(dateSelected))
            .collection('exercise-log')
            .doc(exerciseId)
            .delete();
    }

    addNote(
        coachId: string,
        clientId: string,
        dateSelected: Date,
        exerciseId: string,
        notes: string
    ) {
        return this.firestore
            .collection('users')
            .doc(coachId)
            .collection('users')
            .doc(clientId)
            .collection('training-sessions')
            .doc(this.formatDatetoStartOfDayString(dateSelected))
            .collection('exercise-log')
            .doc(exerciseId)
            .set(
                {
                    notes: notes,
                },
                { merge: true }
            );
    }

    formatDatetoStartOfDayString(logDate: Date): string {
        let newDate = logDate;

        newDate.setHours(0, 0, 0, 0);
        return newDate.toISOString();
    }
}
