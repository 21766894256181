import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot,
} from '@angular/router';
import { filter, forkJoin, Observable, take } from 'rxjs';
import { MessagesService } from 'app/layout/common/messages/messages.service';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { NotificationsService } from 'app/layout/common/notifications/notifications.service';
import { ChatService } from 'app/layout/common/quick-chat/quick-chat.service';
import { ShortcutsService } from 'app/layout/common/shortcuts/shortcuts.service';
import { UserService } from 'app/core/user/user.service';
import { UserStore } from './core/global-stores/user.store';
import { CoachSubscriptionStore } from './core/global-stores/subscription.store';

@Injectable({
    providedIn: 'root',
})
export class InitialDataResolver implements Resolve<any> {
    /**
     * Constructor
     */
    constructor(
        private messagesService: MessagesService,
        private navigationService: NavigationService,
        private notificationsService: NotificationsService,
        private quickChatService: ChatService,
        private shortcutsService: ShortcutsService,
        private userService: UserService,
        private _subscriptionStore: CoachSubscriptionStore,
        private userStore: UserStore
    ) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Use this resolver to resolve initial mock-api for the application
     *
     * @param route
     * @param state
     */
    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> {
        // Fork join multiple API endpoint calls to wait all of them to finish
        return forkJoin([
            this.navigationService.get().pipe(take(1)),
            this.userStore.getLoading.pipe(
                filter((loading) => !loading),
                take(1)
            ),
            this.messagesService.getAll(),
            this.notificationsService.getAll(),
            this.quickChatService.getChats(),
            this.shortcutsService.getAll(),
            this.userService.get(),
        ]);
    }
}
