import { Injectable } from '@angular/core';
import {
    AngularFirestore,
    DocumentChangeAction,
} from '@angular/fire/compat/firestore';
import { CoachKitProduct } from 'app/models/coachkitProduct.model';
import { map, Observable } from 'rxjs';
import { CoachSubscription } from 'app/models/coachSubscription.model';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Price } from 'app/models/price.model';
import { PaymentLink } from 'app/models/paymentLink.model';

@Injectable({
    providedIn: 'root',
})
export class CoachKitProductsService {
    constructor(
        private firestore: AngularFirestore,
        private fns: AngularFireFunctions
    ) {}

    getPlans(): Observable<CoachKitProduct[]> {
        return this.firestore
            .collection('products', (ref) =>
                ref.where('show', '==', true).orderBy('order', 'asc')
            )
            .snapshotChanges()
            .pipe(
                map((actions: DocumentChangeAction<CoachKitProduct>[]) => {
                    return actions.map(
                        (action: DocumentChangeAction<CoachKitProduct>) => {
                            const data: any = action.payload.doc.data();
                            const id: string = action.payload.doc.ref.id;
                            return { id, ...data } as CoachKitProduct;
                        }
                    );
                })
            );
    }

    getProduct(productId: string): Observable<CoachKitProduct> {
        return this.firestore
            .collection('products')
            .doc(productId)
            .snapshotChanges()
            .pipe(
                map(
                    (a: any) => {
                        const data = a?.payload?.data();
                        const id = a?.payload?.id;

                        return { id, ...data } as CoachKitProduct;
                    },
                    (error) => {
                        console.log('An error occured: ', error);
                    }
                )
            );
    }

    getAdditionalCoachProduct() {
        return this.firestore
            .collection('products', (ref) =>
                ref.where('additionalCoach', '==', true)
            )
            .snapshotChanges()
            .pipe(
                map((actions: DocumentChangeAction<CoachKitProduct>[]) => {
                    return actions.map(
                        (action: DocumentChangeAction<CoachKitProduct>) => {
                            const data: any = action.payload.doc.data();
                            const id: string = action.payload.doc.ref.id;
                            return { id, ...data } as CoachKitProduct;
                        }
                    );
                })
            );
    }

    addCoachSeatToSubscription(
        orgId: string,
        subscriptionId: string,
        priceId: string,
        totalSeats: number
    ) {
        const coachSeats = this.fns.httpsCallable('addCoachSeats');

        return coachSeats({
            orgId: orgId,
            subscriptionId: subscriptionId,
            priceId: priceId,
            totalSeats: totalSeats,
        }).toPromise();
    }

    getPrices(productId: string): Observable<Price[]> {
        return this.firestore
            .collection('products')
            .doc(productId)
            .collection('prices', (ref) =>
                ref.where('active', '==', true).limit(1)
            )
            .snapshotChanges()
            .pipe(
                map((actions: DocumentChangeAction<Price>[]) => {
                    return actions.map(
                        (action: DocumentChangeAction<Price>) => {
                            const data: any = action.payload.doc.data();
                            const id: string = action.payload.doc.ref.id;
                            const amount = this.formatPriceTo2dp(
                                data.unit_amount
                            );

                            return { id, ...data, amount } as Price;
                        }
                    );
                })
            );
    }

    formatPriceTo2dp(amountInPence: number) {
        const amountInPounds = amountInPence / 100;

        // Format the number to two decimal places
        const formattedAmount = amountInPounds.toFixed(2);

        return formattedAmount;
    }

    getCurrentSubscription(orgId: string): Observable<CoachSubscription[]> {
        return this.firestore
            .collection('users')
            .doc(orgId)
            .collection('subscriptions', (ref) =>
                ref.where('status', 'in', ['trialing', 'active'])
            )
            .snapshotChanges()
            .pipe(
                map((actions: DocumentChangeAction<CoachSubscription>[]) => {
                    return actions.map(
                        (action: DocumentChangeAction<CoachSubscription>) => {
                            const data: any = action.payload.doc.data();
                            const id: string = action.payload.doc.ref.id;
                            return { id, ...data } as CoachSubscription;
                        }
                    );
                })
            );
    }

    getAllSubscriptions(orgId: string): Observable<CoachSubscription[]> {
        return this.firestore
            .collection('users')
            .doc(orgId)
            .collection('subscriptions')
            .snapshotChanges()
            .pipe(
                map((actions: DocumentChangeAction<CoachSubscription>[]) => {
                    return actions.map(
                        (action: DocumentChangeAction<CoachSubscription>) => {
                            const data: any = action.payload.doc.data();
                            const id: string = action.payload.doc.ref.id;
                            return { id, ...data } as CoachSubscription;
                        }
                    );
                })
            );
    }

    generatePaymentLinkForSubscriptionAndFreeTrial(
        orgId: string,
        priceId: string,
        freeTrial: boolean = false
    ): Promise<any> {
        const paymentLink = this.fns.httpsCallable(
            'createFreeTrialWithPaymentLink'
        );

        return paymentLink({
            orgId: orgId,
            priceId: priceId,
            freeTrial: freeTrial,
        }).toPromise();
    }

    downgradePlan(orgId: string, subId: string, priceId: string) {
        const downgrade = this.fns.httpsCallable('downgradePlan');

        return downgrade({
            orgId: orgId,
            subscriptionId: subId,
            newPriceId: priceId,
        }).toPromise();
    }

    upgradePlan(orgId: string, subId: string, priceId: string) {
        const downgrade = this.fns.httpsCallable('upgradePlan');

        return downgrade({
            orgId: orgId,
            subscriptionId: subId,
            newPriceId: priceId,
        }).toPromise();
    }

    cancelSub(orgId: string, subId: string) {
        const cancelSubscription = this.fns.httpsCallable(
            'cancelSubscriptionAtEndOfBillngPeriod'
        );

        return cancelSubscription({
            orgId: orgId,
            subscriptionId: subId,
        }).toPromise();
    }

    resumeCanceledSubscription(orgId: string, subId: string) {
        const resume = this.fns.httpsCallable('resumeCanceledSubscription');

        return resume({
            orgId: orgId,
            subscriptionId: subId,
        }).toPromise();
    }

    getPaymentLink(orgId: string, checkoutId: string): Observable<PaymentLink> {
        return this.firestore
            .collection('users')
            .doc(orgId)
            .collection('checkout_sessions')
            .doc(checkoutId)
            .snapshotChanges()
            .pipe(
                map(
                    (a: any) => {
                        const data = a?.payload?.data();
                        const id = a?.payload?.id;

                        return { ...data, id } as PaymentLink;
                    },
                    (error) => {
                        console.log('An error occured: ', error);
                    }
                )
            );
    }

    generateStripePortalLink() {
        const generatePortalLinkFunctionRef = this.fns.httpsCallable(
            'ext-firestore-stripe-payments-createPortalLink'
        );

        return generatePortalLinkFunctionRef({
            returnUrl: window.location.origin,
        }).toPromise();
    }
}
