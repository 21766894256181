import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';

import { AngularFireFunctions } from '@angular/fire/compat/functions';
@Injectable({
    providedIn: 'root',
})
export class StripeService {
    constructor(
        private firestore: AngularFirestore,
        private fns: AngularFireFunctions
    ) {}

    onboardOrUpdateAccount(orgId: string): Promise<any> {
        const register = this.fns.httpsCallable(
            'generateStripeOnboardingOrClientUpdateUrl'
        );
        return register({ orgId: orgId }).toPromise();
    }

    enableStripe(userId: string, enable: boolean) {
        return this.firestore
            .collection('users')
            .doc(userId)
            .set(
                {
                    paymentOptions: {
                        stripe: { enabled: enable },
                    },
                },
                { merge: true }
            );
    }

    getProducts(): Promise<any> {
        const getStripeProducts = this.fns.httpsCallable('getStripeProducts');
        return getStripeProducts({}).toPromise();
    }
}
