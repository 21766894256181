import { Injectable } from '@angular/core';
import { Resolve, Router } from '@angular/router';
import { CoachSubscriptionStore } from 'app/core/global-stores/subscription.store';
import { Subscription } from 'app/models/subscriptions';
import { Observable, of } from 'rxjs';
import { filter, first, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class SubscriptionResolver implements Resolve<Subscription | null> {
    constructor(
        private coachSubscriptionStore: CoachSubscriptionStore,
        private router: Router
    ) {}

    resolve(): Observable<Subscription | null> {
        // Trigger loading of the subscription
        this.coachSubscriptionStore.loadUsersSubscription();

        // Return the observable that resolves the subscription value
        return this.coachSubscriptionStore.getSubscription.pipe(
            filter((subscription) => subscription !== undefined), // Wait until the subscription is defined
            first() // Ensure the observable completes after the first value
        );
    }
}
