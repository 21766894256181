import { Component } from '@angular/core';

import { StatusBar, Style } from '@capacitor/status-bar';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent {
    /**
     * Constructor
     */
    constructor() {
        this.initializeApp();
    }

    async initializeApp() {
        StatusBar.setOverlaysWebView({ overlay: false });
        StatusBar.setBackgroundColor({ color: '#ffffff' }); // Set your color here
        StatusBar.setStyle({ style: Style.Light }); // Adjust style for iOS
    }
}
