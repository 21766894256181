<nav
    mat-tab-nav-bar
    class="header-bg-white bg-white w-full shadow-2xl border-t pb-4"
>
    <a
        *ngFor="let item of navigationItems"
        mat-tab-link
        [routerLink]="item.link"
        class="w-full flex flex-col items-center mt-2"
    >
        <mat-icon
            [svgIcon]="item.icon"
            class="icon-size-6"
            [ngClass]="{ 'text-primary': activeLink === item.link }"
        ></mat-icon>
        <span
            class="text-xs mt-1"
            [ngClass]="{ 'text-primary': activeLink === item.link }"
            >{{ item.title }}</span
        >
    </a>

    <a
        mat-tab-link
        (click)="toggleNavigation('mainNavigation')"
        class="w-full flex flex-col items-center mt-2"
    >
        <mat-icon
            class="icon-size-6"
            [ngClass]="{ 'text-primary': mainMenuOpened }"
            >menu</mat-icon
        >
        <span
            class="text-xs mt-1"
            [ngClass]="{ 'text-primary': mainMenuOpened }"
            >Menu</span
        >
    </a>
</nav>
