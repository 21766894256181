import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CoachSubscriptionStore } from 'app/core/global-stores/subscription.store';
import { UserStore } from 'app/core/global-stores/user.store';
import { UserType } from 'app/enums/user-type.enum';
import { Observable, combineLatest } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class SubscriptionValidationService {
    constructor(
        private router: Router,
        private subscriptionStore: CoachSubscriptionStore,
        private _userStore: UserStore
    ) {}

    validateSubscription(): Observable<any> {
        return combineLatest([
            this._userStore.getUser,
            this.subscriptionStore.getSubscription,
        ]).pipe(
            tap(([user, subscription]) => {
                if (user?.type === UserType.COACH && !subscription) {
                    console.warn('No valid subscription found. Redirecting...');
                    this.router.navigate(['/subscription/no-plan']);
                }
            })
        );
    }

    enableControlsIfValid(): Observable<boolean> {
        return combineLatest([
            this._userStore.getUser,
            this.subscriptionStore.getSubscription,
        ]).pipe(
            map(([user, subscription]) => {
                if (user?.type === UserType.COACH && subscription) {
                    return true;
                } else if (user?.type === UserType.CLIENT) {
                    return true;
                }
                return false;
            })
        );
    }
}
