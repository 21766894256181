import { FuseNavigationItem } from '@fuse/components/navigation';
import { environment } from 'environments/environment';

export const coachUserNavigationTree: FuseNavigationItem[] = [
    {
        id: 'main',
        title: 'Dashboard',
        type: 'basic',
        link: '/dashboard',
        icon: 'mat_outline:dashboard',
        children: [],
    },
    {
        id: 'clients',
        title: 'Clients',
        type: 'basic',
        icon: 'heroicons_outline:user-group',
        link: '/clients',
    },
    {
        id: 'checkins',
        title: 'Check-ins',
        type: 'basic',
        icon: 'heroicons_outline:clipboard-list',
        link: '/checkins',
    },
    {
        id: 'todo',
        title: 'Task Boards',
        type: 'basic',
        icon: 'heroicons_outline:calendar',
        link: '/todo',
    },
    {
        id: 'plans',
        title: 'Training Plans',
        type: 'basic',
        icon: 'mat_outline:directions_run',
        link: '/plans/training',
    },
    {
        id: 'recipes',
        title: 'Recipes',
        type: 'basic',
        icon: 'mat_outline:restaurant',
        link: '/recipes',
        badge: {
            classes: 'bg-primary px-1 rounded-full',
            title: 'NEW',
        },
    },
    {
        id: 'chat',
        title: 'Chat',
        type: 'basic',
        icon: 'mat_outline:chat',
        link: '/chat',
    },
    {
        id: 'document-vault',
        title: 'Vault',
        type: 'basic',
        icon: 'mat_outline:drive_folder_upload',
        link: '/vault',
    },
    {
        id: 'forms',
        title: 'Forms',
        type: 'basic',
        link: '/forms/view',
        icon: 'heroicons_outline:document-text',
    },
    {
        id: 'services',
        title: 'Services',
        type: 'basic',
        link: '/services',
        icon: 'heroicons_outline:shopping-cart',
    },
    {
        id: 'account',
        title: 'Account',
        type: 'basic',
        icon: 'heroicons_outline:user-circle',
        link: '/settings',
        meta: {
            isAdmin: true,
        },
    },
];

export const clientUserNavigationTree: FuseNavigationItem[] = [
    {
        id: 'main',
        title: 'Home',
        type: 'basic',
        link: '/home',
        icon: 'mat_outline:home',
        children: [],
    },
    {
        id: 'checkins',
        title: 'Check-ins',
        type: 'basic',
        icon: 'mat_outline:checklist',
        link: '/your-check-ins',
    },
    {
        id: 'gallery',
        title: 'Gallery',
        type: 'basic',
        icon: 'mat_outline:photo_library',
        link: '/your-gallery',
    },
    {
        id: 'nutrition',
        title: 'Nutrition',
        type: 'basic',
        icon: 'mat_outline:blender',
        link: '/your-nutrition',
    },
    {
        id: 'recipes',
        title: 'Recipes',
        type: 'basic',
        icon: 'mat_outline:restaurant',
        link: '/recipes',
        badge: {
            classes: 'bg-primary px-1 rounded-full',
            title: 'NEW',
        },
    },
    {
        id: 'training',
        title: 'Training Plan',
        link: '/your-training-plan',
        type: 'basic',
        icon: 'mat_outline:fitness_center',
    },
    {
        id: 'workouts',
        title: 'Exercise Log',
        type: 'basic',
        icon: 'mat_outline:content_paste',
        link: '/workouts',
    },
    {
        id: 'supplements',
        title: 'Supplements',
        type: 'basic',
        icon: 'mat_outline:list_alt',
        link: '/your-supplements',
    },
    {
        id: 'forms',
        title: 'Forms',
        type: 'basic',
        icon: 'mat_outline:dynamic_form',
        link: '/your-forms',
    },
    {
        id: 'vault',
        title: 'Vault',
        type: 'basic',
        icon: 'mat_outline:drive_folder_upload',
        link: '/your-vault',
    },
    {
        id: 'metrics',
        title: 'Metrics',
        type: 'basic',
        icon: 'mat_outline:assessment',
        link: '/your-metrics',
    },
    {
        id: 'measurements',
        title: 'Measurements',
        type: 'basic',
        icon: 'mat_outline:straighten',
        link: '/your-measurements',
    },
    {
        id: 'chat',
        title: 'Chat',
        type: 'basic',
        icon: 'mat_outline:chat',
        link: '/chat',
    },
    {
        id: 'help',
        title: 'Help',
        type: 'basic',
        icon: 'heroicons_outline:question-mark-circle',
        link: '/help',
        hidden: (item) => !environment.CONFIG.showHelpCenter,
    },
];

export const clientToolbarNavigationItems: FuseNavigationItem[] = [
    {
        link: '/home',
        type: 'basic',
        icon: 'mat_outline:home',
        title: 'Home',
    },
    {
        link: '/your-nutrition',
        type: 'basic',
        icon: 'mat_outline:blender',
        title: 'Nutrition',
    },
    {
        link: '/workouts',
        type: 'basic',
        icon: 'mat_outline:fitness_center',
        title: 'Workout',
    },
];
