import { Injectable } from '@angular/core';
import {
    AngularFirestore,
    DocumentChangeAction,
} from '@angular/fire/compat/firestore';
import { Client } from 'app/models/client';
import { Observable, BehaviorSubject, catchError } from 'rxjs';
import { map } from 'rxjs/operators';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { NotificationsService } from 'app/layout/common/notifications/notifications.service';
import { NotificationType } from 'app/layout/common/notifications/notification-types.enum';

@Injectable({
    providedIn: 'root',
})
export class ClientService {
    private _selectedClient: BehaviorSubject<Client | null> =
        new BehaviorSubject(null);

    constructor(
        private firestore: AngularFirestore,
        private fns: AngularFireFunctions,
        private _notificationService: NotificationsService
    ) {}

    getClients(
        orgId: string,
        coachedById?: string,
        isAdmin: boolean = true
    ): Observable<Client[]> {
        if (isAdmin) {
            return this.firestore
                .collection('users')
                .doc(orgId)
                .collection('users', ref => ref.orderBy('firstName', 'asc'))
                .snapshotChanges()
                .pipe(
                    map((actions: DocumentChangeAction<Client>[]) =>
                        actions.map((action: DocumentChangeAction<Client>) => {
                            const data: any = action.payload.doc.data();
                            const id: string = action.payload.doc.ref.id;
                            return { id, ...data } as Client;
                        })
                    )
                );
        } else {
            return this.firestore
                .collection('users')
                .doc(orgId)
                .collection('users', ref =>
                    ref
                        .orderBy('firstName', 'asc')
                        .where('coachedBy', '==', coachedById)
                )
                .snapshotChanges()
                .pipe(
                    map((actions: DocumentChangeAction<Client>[]) =>
                        actions.map((action: DocumentChangeAction<Client>) => {
                            const data: any = action.payload.doc.data();
                            const id: string = action.payload.doc.ref.id;
                            return { id, ...data } as Client;
                        })
                    )
                );
        }
    }

    getUser(userId: string): Observable<Client> {
        return this.firestore
            .collectionGroup('users', ref =>
                ref.where('uid', '==', userId).limit(1)
            )
            .snapshotChanges()
            .pipe(
                map((actions: DocumentChangeAction<Client>[]) => {
                    if (actions?.length > 0) {
                        const user = actions[0];
                        const data: any = user.payload.doc.data();
                        const id: string = user.payload.doc.ref.id;
                        return { id, ...data } as Client;
                    } else {
                        return null;
                    }
                })
            );
    }

    getUserOnce(clientId: string): Promise<Client> {
        return this.firestore
            .collectionGroup('users', ref => ref.where('uid', '==', clientId))
            .get()
            .toPromise();
    }

    getClientsByCheckinDay(
        orgId: string,
        checkInDay: number,
        coachedById?: string,
        isAdmin: boolean = false
    ): Observable<Client[]> {
        if (isAdmin) {
            return this.firestore
                .collection('users')
                .doc(orgId)
                .collection('users', ref =>
                    ref.where('checkinDay', '==', checkInDay)
                )
                .snapshotChanges()
                .pipe(
                    map((actions: DocumentChangeAction<Client>[]) =>
                        actions.map((action: DocumentChangeAction<Client>) => {
                            const data: any = action.payload.doc.data();
                            const id: string = action.payload.doc.ref.id;
                            return { id, ...data } as Client;
                        })
                    )
                );
        } else {
            return this.firestore
                .collection('users')
                .doc(orgId)
                .collection('users', ref =>
                    ref
                        .where('coachedBy', '==', coachedById)
                        .where('checkinDay', '==', checkInDay)
                )
                .snapshotChanges()
                .pipe(
                    map((actions: DocumentChangeAction<Client>[]) =>
                        actions.map((action: DocumentChangeAction<Client>) => {
                            const data: any = action.payload.doc.data();
                            const id: string = action.payload.doc.ref.id;
                            return { id, ...data } as Client;
                        })
                    )
                );
        }
    }

    getClientsWithLateCheckins(
        orgId: string,
        coachedById?: string,
        isAdmin: boolean = false
    ) {
        if (isAdmin) {
            return this.firestore
                .collection('users')
                .doc(orgId)
                .collection('users')
                .snapshotChanges()
                .pipe(
                    map((actions: DocumentChangeAction<Client>[]) =>
                        actions.map((action: DocumentChangeAction<Client>) => {
                            const data: any = action.payload.doc.data();
                            const id: string = action.payload.doc.ref.id;
                            return { id, ...data } as Client;
                        })
                    )
                );
        } else {
            return this.firestore
                .collection('users')
                .doc(orgId)
                .collection('users', _ =>
                    _.where('coachedBy', '==', coachedById)
                )
                .snapshotChanges()
                .pipe(
                    map((actions: DocumentChangeAction<Client>[]) =>
                        actions.map((action: DocumentChangeAction<Client>) => {
                            const data: any = action.payload.doc.data();
                            const id: string = action.payload.doc.ref.id;
                            return { id, ...data } as Client;
                        })
                    )
                );
        }
    }

    getClientsUpForRenewal(
        orgId: string,
        coachedById: string,
        isAdmin: boolean = false,
        noOfDaysPriorToPlanEndDate: number = 7
    ) {
        const expiryThreshold = new Date();
        expiryThreshold.setDate(
            expiryThreshold.getDate() + noOfDaysPriorToPlanEndDate
        );

        if (isAdmin) {
            return this.firestore
                .collection('users')
                .doc(orgId)
                .collection('users', ref =>
                    ref

                        .where('plan.endDate', '<=', expiryThreshold)
                        .orderBy('plan.endDate', 'asc')
                )
                .snapshotChanges()
                .pipe(
                    map((actions: DocumentChangeAction<Client>[]) =>
                        actions.map((action: DocumentChangeAction<Client>) => {
                            const data: any = action.payload.doc.data();
                            const id: string = action.payload.doc.ref.id;
                            return { id, ...data } as Client;
                        })
                    )
                );
        } else {
            return this.firestore
                .collection('users')
                .doc(orgId)
                .collection('users', ref =>
                    ref
                        .where('coachedBy', '==', coachedById)
                        .where('plan.endDate', '<=', expiryThreshold)
                        .orderBy('plan.endDate', 'asc')
                )
                .snapshotChanges()
                .pipe(
                    map((actions: DocumentChangeAction<Client>[]) =>
                        actions.map((action: DocumentChangeAction<Client>) => {
                            const data: any = action.payload.doc.data();
                            const id: string = action.payload.doc.ref.id;
                            return { id, ...data } as Client;
                        })
                    )
                );
        }
    }

    addUsers(coachId: string, client: Client, sendPaymentLink: boolean) {
        client.coachId = coachId;
        const inviteUsers = this.fns.httpsCallable('inviteUsers');
        return inviteUsers({ client: client, sendPaymentLink: sendPaymentLink })
            .toPromise()
            .then((user) => {
                console.log(`User: ${user}`);
            })
            .catch((error) => {
                console.error(error);
                throw new Error(error);
            });
    }

    deleteUser(coachId: string, userId: string): Promise<any> {
        const deleteUser = this.fns.httpsCallable('deleteUser');
        return deleteUser({ clientId: userId, orgId: coachId })
            .toPromise()
            .then(() => {})
            .catch((error) => {
                console.error(error);
                throw new Error(error);
            });
    }

    getClientCollection(
        clientDetails: Partial<Client>,
        collectionName: string = 'standard-forms'
    ): Observable<any[]> {
        return this.firestore
            .collection('users')
            .doc(clientDetails.coachId)
            .collection('users')
            .doc(clientDetails.uid)
            .collection(collectionName)
            .snapshotChanges()
            .pipe(
                map((actions: DocumentChangeAction<any>[]) =>
                    actions.map((action: DocumentChangeAction<Client>) => {
                        const data: any = action.payload.doc.data();
                        const id: string = action.payload.doc.ref.id;
                        return { id, ...data } as any;
                    })
                )
            );
    }

    updateClientPartial(
        coachId: string,
        clientId: string,
        data: Partial<Client>
    ) {
        return this.firestore
            .collection('users')
            .doc(coachId)
            .collection('users')
            .doc(clientId)
            .set(data, { merge: true });
    }

    renewClient(
        coachId: string,
        clientId: string,
        data: any,
        sendPaymentLink: boolean
    ) {
        const renewClient = this.fns.httpsCallable('renewClient');
        return renewClient({
            client: clientId,
            coachId: coachId,
            sendPaymentLink: sendPaymentLink,
            renewalData: data,
        })
            .toPromise()
            .then((user) => {
                console.log(`User: ${user}`);
            })
            .catch((error) => {
                console.error(error);
                throw new Error(error);
            });
    }

    // Update the users form collection
    async assignFormToRelevantCollection(
        clientDetails: Partial<Client>,
        form: any
    ) {
        let batch = this.firestore.firestore.batch();

        // Save the form to the clients standard or checkin form collection
        const clientRef = this.firestore
            .collection('users')
            .doc(clientDetails.coachId)
            .collection('users')
            .doc(clientDetails.uid);

        const clientFormCollectionRef = clientRef
            .collection(
                form?.isCheckinForm ? 'checkin-forms' : 'standard-forms'
            )
            .doc(form.details.formId).ref;

        const formPayload = {
            id: form.details.formId,
            formName: form.details.formName,
            dateAssigned: new Date(),
        };

        // If the form is a standard form, add the date completed
        !form.isCheckinForm &&
            Object.assign(formPayload, {
                created: null,
                completed: false,
            });

        batch.set(clientFormCollectionRef, formPayload, {
            merge: true,
        });
        batch = this._notificationService.createClientNotificationsWithBatch(
            clientRef.ref,
            NotificationType.NEW_FORM_ASSIGNED,
            batch,
            `/forms/submit/${form.details.formId}`
        );

        // Save the form to the clients forms collection
        try {
            return await batch
                .commit()
                .then(() => true)
                .catch((error) => {
                    console.log('An error occured: ', error);
                    return false;
                });
        } catch (error) {
            console.log('An error occured: ', error);
        }
    }

    // Delete form from the users form collection
    async unassignFormFromRelevantCollection(
        coachId: string,
        clientId: string,
        formId: string,
        isCheckinForm: boolean
    ) {
        const clientFormCollectionRef = this.firestore
            .collection('users')
            .doc(coachId)
            .collection('users')
            .doc(clientId)
            .collection(isCheckinForm ? 'checkin-forms' : 'standard-forms')
            .doc(formId).ref;

        const batch = this.firestore.firestore.batch();

        batch.delete(clientFormCollectionRef);

        try {
            return await batch
                .commit()
                .then(() => true)
                .catch((error) => {
                    console.log('An error occured: ', error);
                    return false;
                });
        } catch (error) {
            console.log('An error occured: ', error);
        }
    }

    get selectedClient$(): Observable<Client> {
        return this._selectedClient.asObservable();
    }
}
