import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Coach } from 'app/models/coach';
import { Subscription } from 'app/models/subscriptions';
import { CoachKitProductsService } from 'app/modules/admin/pages/settings/plan-billing/coachkit-product.service';
import { pipe } from 'rxjs';
import {
    switchMap,
    tap,
    catchError,
    retry,
    finalize,
    filter,
} from 'rxjs/operators';
import { UserStore } from './user.store';

interface SubscriptionState {
    subscription: Subscription | null;
    loading: boolean;
}

@Injectable({ providedIn: 'root' })
export class CoachSubscriptionStore extends ComponentStore<SubscriptionState> {
    constructor(
        private userStore: UserStore,
        private coachSubscription: CoachKitProductsService
    ) {
        super({ subscription: undefined, loading: null });
        this.loadUsersSubscription();
    }

    readonly getSubscription = this.select((state) => state.subscription);
    readonly isLoading = this.select((state) => state.loading);

    readonly setSubscription = this.updater<Subscription | null>(
        (state, subscription) => ({
            ...state,
            subscription,
        })
    );

    readonly setLoading = this.updater<boolean>((state, loading) => ({
        ...state,
        loading,
    }));

    readonly loadUsersSubscription = this.effect<void>(
        pipe(
            tap(() => this.setLoading(true)),
            switchMap(() =>
                this.userStore.getUser.pipe(
                    // Filter out null or undefined users because it could still be loading the user
                    filter(
                        (user: Coach | null) =>
                            user !== null && user !== undefined
                    ),
                    switchMap((user: Coach) =>
                        this.coachSubscription.getCurrentSubscription(
                            user.memberOf ?? user.uid
                        )
                    ),
                    tap((subscriptions: any) => {
                        console.log(`Subscription is: ${subscriptions}`);
                        this.setSubscription(
                            subscriptions?.length > 0 ? subscriptions[0] : null
                        );
                    }),
                    catchError((err) => {
                        console.error('Error loading subscription:', err);
                        this.setSubscription(undefined); // Clear subscription on error
                        return [];
                    }),
                    retry(2),
                    finalize(() => this.setLoading(false))
                )
            )
        )
    );
}
