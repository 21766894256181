import { round } from 'lodash';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const toFixedNumber = (
    value: string | number,
    precision: number = 2
): number => {
    if (!value) {
        return 0;
    }

    if (typeof value === 'string') {
        return round(parseFloat(value), precision);
    }
    return round(value, precision);
};

export function noWhitespaceValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const isWhitespace = (control?.value || '').trim().length === 0;
        const isValid = !isWhitespace;
        return isValid ? null : { whitespace: true };
    };
}
