import {
    ChangeDetectorRef,
    Component,
    OnInit,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SubscribeUntilDestroyedComponent } from 'app/common/subscribe-until-destroyed.component';
import { Exercise } from 'app/models/exercise';
import { ExerciseLog, SessionLog } from 'app/models/exerciseSessionLog';
import {
    TrainingPlan,
    TrainingPlanWeek,
    TrainingSession,
} from 'app/models/trainingPlan';
import { ExerciseService } from 'app/modules/exercises/exercise.service';
import { TrainingPlanNewService } from 'app/modules/training-plans/training-plan-new.service';
import moment from 'moment';
import { combineLatest, tap } from 'rxjs';
import { switchMap, take } from 'rxjs';
import { ExerciseLogService } from './exercise-log.service';
import { getCurrentWeek } from 'app/modules/training-plans/helpers/training-plan.helper';
import { MatDrawer } from '@angular/material/sidenav';
import { ActivatedRoute, Router } from '@angular/router';
import { SnackbarService } from 'app/shared/snackbar/snackbar.service';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { UserStore } from 'app/core/global-stores/user.store';

@Component({
    selector: 'exercise-log',
    templateUrl: './exercise-log.component.html',
    styleUrls: ['./exercise-log.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class WorkoutLogComponent
    extends SubscribeUntilDestroyedComponent
    implements OnInit
{
    @ViewChild('matDrawer', { static: true }) public matDrawer: MatDrawer;
    drawerMode: 'side' | 'over';

    session: SessionLog;

    sessions: SessionLog[];

    exercises: ExerciseLog[] = [];

    allExercises: Exercise[] = [];

    days: Date[] = [];

    loading: boolean = false;

    isToday: boolean = true;

    latestSession: SessionLog;

    durationInMinutes: number;

    showFabIcon: boolean = true;

    plan: TrainingPlan;
    planId: string;

    trainingPlanSessions: TrainingSession[] = [];
    trainingPlanExercises: Exercise[] = [];
    todaysSession: TrainingSession;

    planAssignedFrom: Date;

    currentWeek: TrainingPlanWeek;

    selectSession: boolean = false;
    previewSession: TrainingSession;

    drawerExercise: ExerciseLog;

    clientId: string;
    coachId: string;

    weight: number = 10;
    reps: number = 10;

    dateSelected: Date;
    today = new Date();

    dateOptions: Date[] = [];

    displayTimer: boolean = false;

    planType: number;

    constructor(
        private _exerciseService: ExerciseService,
        private _exerciseLogService: ExerciseLogService,
        private _trainingPlanService: TrainingPlanNewService,
        private _activatedRoute: ActivatedRoute,
        private _changeDetectorRef: ChangeDetectorRef,
        private _router: Router,
        private _userStore: UserStore,
        private _snackbarService: SnackbarService,
        private _fuseConfirmationService: FuseConfirmationService
    ) {
        super();
    }

    getDefaultDate() {
        if (this._exerciseLogService?.workoutSelected?.getValue()) {
            return this.dateOptions.find((_) =>
                moment(_).isSame(
                    this._exerciseLogService?.workoutSelected?.getValue(),
                    'day'
                )
            );
        }

        return this.dateOptions[0];
    }

    ngOnInit(): void {
        this.today.setHours(0, 0, 0, 0);
        this.dateOptions = this.preFillDates();
        this.dateSelected = this.getDefaultDate();

        //Check to see if session already started.
        this.loading = true;

        this.subscribe(
            this._userStore.getUser.pipe(
                take(1),
                tap((user) => {
                    this.clientId = user.id;
                    this.coachId = user.coachId;
                })
            )
        );

        this.subscribe(
            combineLatest(
                this._exerciseService.getPreDefinedExercises(),
                this._exerciseService.getExercisesByCoach(this.coachId),
                this._exerciseLogService.getSession(
                    this.coachId,
                    this.clientId,
                    this.dateSelected
                )
            ).pipe(
                tap(([preDefinedExercises, coachExercises, session]) => {
                    this.getUsersTrainingPlan();
                    this.allExercises =
                        this._exerciseService.combineArraysByExerciseId(
                            preDefinedExercises,
                            coachExercises
                        );

                    if (session) {
                        this.latestSession = session;
                        this.changeDay(session?.started);
                    } else {
                        this.loading = false;
                    }
                })
            )
        );

        this.matDrawer.openedChange.subscribe((open: boolean) => {
            if (open) {
                this.showFabIcon = false;
            } else {
                this.showFabIcon = true;
            }
        });
    }

    /**
     *
     */
    preFillDates(
        from: Date = new Date(),
        numberOfDaysToBackfill: number = 30
    ): Date[] {
        let dates = [from];

        let fromStartOfDay = moment(from).startOf('day');

        for (let i = 0; i <= numberOfDaysToBackfill; i++) {
            let r = fromStartOfDay.subtract(1, 'day').toDate();
            dates.push(r);
        }

        return dates;
    }

    onBackdropClicked(): void {
        // Go back to the list
        this._router.navigate(['./'], { relativeTo: this._activatedRoute });

        // Mark for check
        this._changeDetectorRef.markForCheck();
    }

    getUsersTrainingPlan() {
        this.subscribe(
            this._trainingPlanService
                .getClientsTrainingPlan(this.clientId)
                .pipe(
                    take(1),
                    switchMap(([plan]) => {
                        this.planAssignedFrom = plan?.assignedFrom;
                        this.planAssignedFrom?.setHours(0, 0, 0, 0);
                        this.planId = plan.planId;
                        return this._trainingPlanService.getWeeks(
                            this.coachId,
                            plan.planId
                        );
                    }),
                    switchMap((weeks) => {
                        this.currentWeek = getCurrentWeek(
                            this.planAssignedFrom,
                            weeks
                        );

                        return combineLatest(
                            this._trainingPlanService.getTrainingPlan(
                                this.coachId,
                                this.planId
                            ),
                            this._trainingPlanService.getTrainingSessions(
                                this.coachId,
                                this.planId,
                                this.currentWeek?.id
                            ),

                            this._trainingPlanService.getExercises(
                                this.coachId,
                                this.planId,
                                this.currentWeek?.id
                            )
                        );
                    }),
                    tap(([plan, sessions, exercicses]) => {
                        this.planType = plan?.type ?? 1;

                        this.trainingPlanSessions = this.mapExercisesToSessions(
                            sessions,
                            exercicses
                        );

                        //Add current sessions to the screen.
                        let current = null;

                        //Calendar plan
                        if (this.planType === 3) {
                            current = this.trainingPlanSessions.find(
                                (_) =>
                                    _.sessionId ==
                                    this.dateSelected?.toISOString()
                            );
                        } else {
                            this.trainingPlanSessions.find(
                                (_) => _.dayOfWeek === new Date().getDay()
                            );
                        }

                        if (current) {
                            //Order as per training plan.
                            current.exercises = this.sortExercisesInAscOrder(
                                current.exercises
                            );
                        }

                        this.todaysSession = current;
                    })
                )
        );
    }

    updateNotes(exerciseLog: ExerciseLog, notes: string) {
        try {
            this._exerciseLogService.addNote(
                this.coachId,
                this.clientId,
                this.dateSelected,
                exerciseLog.id,
                notes
            );
        } catch (err) {
            console.error(`Unable to update notes for exericse`, err);
            this._snackbarService.error(`Error adding notes`);
        }
    }

    mapExercisesToSessions(
        sessions: TrainingSession[],
        exercises: Exercise[]
    ): TrainingSession[] {
        // Assign exercises to their sessions.
        sessions = sessions.map((session: TrainingSession) => {
            session.exercises = exercises
                ?.filter((exercise) => exercise.sessionId === session.id)
                .map((exercise) => {
                    // Check if the exercise has sets and if totalSets > 1.
                    if (exercise.sets) {
                        exercise.sets = exercise.sets.flatMap((set) => {
                            // Create an array of sets based on totalSets.
                            const totalSets = set.totalSets || 1;
                            return Array.from({ length: totalSets }, () => ({
                                ...set,
                                totalSets: 1, // Adjust totalSets to 1 for each added set.
                            }));
                        });
                    }
                    return exercise;
                });

            return session;
        });

        return sessions;
    }

    showTimer(show: boolean) {
        this.displayTimer = show;
    }

    changeDay(date: Date) {
        this.dateSelected = date;
        this._exerciseLogService.workoutSelected.next(this.dateSelected);
        this.loading = true;
        this.isToday = this.isDateSelectedToday();
        this.getSession();
    }

    isDateSelectedToday(): boolean {
        return moment(this.today).isSame(this.dateSelected);
    }

    getSession() {
        this.subscribe(
            combineLatest(
                this._exerciseLogService.getSession(
                    this.coachId,
                    this.clientId,
                    this.dateSelected
                ),
                this._exerciseLogService.getExercises(
                    this.coachId,
                    this.clientId,
                    this.dateSelected
                )
            ).pipe(
                tap(([session, exercisesLogged]) => {
                    this.session = session;

                    //If the session has ended, filter the exercises so only see completed sets.
                    if (session?.ended) {
                        exercisesLogged = exercisesLogged?.map((_) => {
                            return {
                                ..._,
                                sets:
                                    _?.sets?.filter(
                                        (_) => _.completed === true
                                    ) ?? [],
                            };
                        });
                    }

                    this.exercises = exercisesLogged
                        ? this.sortExercisesInAscOrder(exercisesLogged)
                        : [];
                    this.loading = false;
                })
            )
        );
    }

    async addExercise(exercise: Exercise) {
        const sortedDesc = this?.exercises?.sort((a, b) =>
            a.position > b.position ? -1 : 1
        );
        const max: number = sortedDesc?.length > 0 ? sortedDesc[0].position : 1;

        exercise.position = exercise.position ?? max + 1;
        exercise.id = null;

        if (!this.session || !this.session.id) {
            //Session hasnt started so begin a new one and select default exercise as one just added.
            this.previewSession = {
                exercises: [exercise],
                name: null,
            };

            await this.beginSession();
        } else {
            //Session already started
            try {
                await this._exerciseLogService.updateExerciseLog(
                    null,
                    this.coachId,
                    this.clientId,
                    this.dateSelected,
                    this.mapExerciseToExerciseLog(exercise, false)
                );
            } catch (err) {
                console.error(`Error adding exercise to current session`, err);
                this._snackbarService.error(
                    `Unable to add exercise to session`
                );
            }
        }
    }

    startSession() {
        //Select session you want to do.
        this.selectSession = true;
        this.previewSession = this.todaysSession;
    }

    sessonPreviewChange(selectedSession: TrainingSession) {
        this.previewSession = selectedSession;
    }

    async beginSession() {
        this.selectSession = false;
        this.exercises = [];
        const newSession: SessionLog = {
            started: new Date(),
        };
        try {
            let defaultExercises = this.exercises.concat(
                this.previewSession?.exercises.map((_) =>
                    this.mapExerciseToExerciseLog(_)
                )
            );

            const result =
                await this._exerciseLogService.createTrainingSessionLog(
                    this.coachId,
                    this.clientId,
                    this.dateSelected,
                    newSession,
                    defaultExercises
                );

            newSession.id = result.id;
            this.session = newSession;
        } catch (err) {
            console.error('Unable to create session', err);
        }
    }

    deleteExercise(exercise: Exercise) {
        try {
            this._exerciseLogService.deleteExercise(
                this.coachId,
                this.clientId,
                this.dateSelected,
                exercise.id
            );
        } catch (err) {
            console.error(`Unable to delete exercise`, err);
            this._snackbarService.error(
                `Error deleting exercise, try again later`
            );
        }
    }

    sortExercisesInAscOrder(exercises: any[]) {
        const a = exercises?.sort((a, b) =>
            a?.position > b?.position ? 1 : -1
        );
        return a;
    }

    mapExerciseToExerciseLog(
        exercise: Exercise,
        logOriginal: boolean = true
    ): ExerciseLog {
        return {
            id: exercise.id ?? null,
            clientId: this.clientId,
            exerciseId: exercise.exerciseId ?? null,
            coachProgrammedExercise: logOriginal ? exercise : null,
            loggedExercise: {
                ...exercise,
                notes: null,
                exerciseId: exercise.exerciseId ?? null,
                id: exercise.id ?? null,
            },

            logged: new Date(),

            position: exercise.position ?? this.exercises?.length ?? 0,
        };
    }

    formatDuration(date: string): string {
        return moment(date, moment.ISO_8601).fromNow(true);
    }

    endSession1() {
        const confirmation = this._fuseConfirmationService.open({
            title: 'Finish Workout',
            message: 'Would you like to end this session?',
            actions: {
                confirm: {
                    label: 'Yes',
                    color: 'primary',
                },
            },
            icon: {
                color: 'primary',
                name: 'mat_outline:content_paste',
                show: true,
            },
            dismissible: true,
        });

        // Subscribe to the confirmation dialog closed action
        confirmation.afterClosed().subscribe(async (result) => {
            // If the confirm button pressed...
            if (result === 'confirmed') {
                //Complete workout
                try {
                    this.session.ended = new Date();
                    await this._exerciseLogService.updateTrainingSessionLog(
                        this.coachId,
                        this.clientId,
                        this.session,
                        this.dateSelected
                    );
                    this._router.navigateByUrl('/workouts/completed');
                } catch (err) {
                    console.error(`Error completing workout`, err);
                    this._snackbarService.error(
                        `Unable to complete workout, try again later`
                    );
                }
            }
        });
    }
    async markAsCompleted(exerciseLog: ExerciseLog, completed: boolean) {
        // Check for incomplete sets.
        const incompleteSetsExist = exerciseLog?.loggedExercise?.sets?.some(
            (set) => !set.completed
        );

        if (completed && incompleteSetsExist) {
            const confirmation = this._fuseConfirmationService.open({
                title: 'Complete exercise',
                message:
                    'You have sets yet to complete, shall we log them as completed?',
                actions: {
                    confirm: {
                        label: 'Yes',
                    },
                },
            });

            // Subscribe to the confirmation dialog's closed action.
            confirmation.afterClosed().subscribe(async (result) => {
                if (result === 'confirmed') {
                    // Mark all sets as completed.
                    exerciseLog.loggedExercise.sets.forEach(
                        (set) => (set.completed = true)
                    );

                    // Set the overall exercise completion status.
                    exerciseLog.loggedExercise.completed = true;

                    try {
                        // Update the exercise log with the new status.
                        await this.updateExercise(exerciseLog);
                    } catch (err) {
                        console.error(`Error completing exercise`, err);
                        this._snackbarService.error(
                            'Unable to complete exercise, try again later'
                        );
                    }
                }
            });
        } else {
            console.log(`Sets already completed or setting to incomplete`);

            // Update the completion status without needing confirmation.
            exerciseLog.loggedExercise.completed = completed;

            try {
                await this.updateExercise(exerciseLog);
            } catch (err) {
                console.error(`Error completing exercise`, err);
                this._snackbarService.error(
                    'Unable to complete exercise, try again later'
                );
            }
        }
    }

    async updateExercise(exercise: ExerciseLog) {
        try {
            await this._exerciseLogService.updateExerciseLog(
                exercise.id,
                this.coachId,
                this.clientId,
                this.dateSelected,
                exercise
            );
        } catch (err) {
            console.error('Unable to update exercise', err);
        }
    }
}
