import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import {
    FuseNavigationService,
    FuseVerticalNavigationComponent,
} from '@fuse/components/navigation';
import { clientToolbarNavigationItems } from 'app/core/navigation/navigation-structure';

@Component({
    selector: 'mobile-navigation',
    templateUrl: './mobile-navigation.component.html',
    styleUrls: ['./mobile-navigation.component.scss'],
})
export class MobileNavigationComponent {
    navigationItems = clientToolbarNavigationItems;

    mainMenuOpened: boolean = false;

    constructor(
        private router: Router,
        private _fuseNavigationService: FuseNavigationService
    ) {}

    get activeLink(): string {
        return this.router.url;
    }

    toggleNavigation(name: string): void {
        // Get the navigation
        const navigation =
            this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(
                name
            );

        if (navigation) {
            // Toggle the opened status
            this.mainMenuOpened = true;
            navigation.toggle();
        }

        navigation.openedChanged.subscribe((open) => {
            this.mainMenuOpened = open ?? false;
        });
    }
}
