import { Route } from '@angular/router';
import { LayoutComponent } from 'app/layout/layout.component';
import { InitialDataResolver } from 'app/app.resolvers';
import {
    AngularFireAuthGuard,
    redirectLoggedInTo,
    AuthPipeGenerator,
} from '@angular/fire/compat/auth-guard';
import { map } from 'rxjs';
import { EmptyLayoutComponent } from './layout/layouts/empty/empty.component';
import { IsUserACoachGuard } from './core/auth/guards/is-user-a-coach.guard';
import { IsUserAClientGuard } from './core/auth/guards/is-user-a-client.guard';
import { SubscriptionResolver } from './modules/admin/coachkit-subscription/subscription-resolver.resolver';

const redirectLoggedInToDashboard: AuthPipeGenerator = () =>
    redirectLoggedInTo(['dashboard']);
const redirectUnauthorizedOrUnverifiedUser: AuthPipeGenerator = () =>
    map((user) => {
        if (user) {
            if (user.emailVerified) {
                return true;
            } else {
                return ['/confirmation-required'];
            }
        } else {
            return ['/sign-in'];
        }
    });

const redirectVerifiedUser: AuthPipeGenerator = () =>
    map((user) => {
        if (user) {
            if (user.emailVerified) {
                return ['dashboard'];
            } else {
                return true;
            }
        } else {
            return true;
        }
    });

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [
    // Redirect empty path to '/example'
    { path: '', pathMatch: 'full', redirectTo: 'dashboard' },

    // Redirect signed in user to the '/example'
    //
    // After the user signs in, the sign in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'dashboard' },
    {
        path: '',
        canActivate: [AngularFireAuthGuard],
        component: LayoutComponent,
        data: {
            authGuardPipe: redirectVerifiedUser,
            layout: 'empty',
        },
        children: [
            {
                path: 'confirmation-required',
                loadChildren: () =>
                    import(
                        'app/modules/auth/confirmation-required/confirmation-required.module'
                    ).then((m) => m.AuthConfirmationRequiredModule),
            },
        ],
    },

    // Auth routes for guests

    {
        path: '',
        canActivate: [AngularFireAuthGuard],
        component: LayoutComponent,
        data: {
            authGuardPipe: redirectLoggedInToDashboard,
            layout: 'empty',
        },
        children: [
            {
                path: 'forgot-password',
                loadChildren: () =>
                    import(
                        'app/modules/auth/forgot-password/forgot-password.module'
                    ).then((m) => m.AuthForgotPasswordModule),
            },
            {
                path: 'reset-password',
                loadChildren: () =>
                    import(
                        'app/modules/auth/reset-password/reset-password.module'
                    ).then((m) => m.AuthResetPasswordModule),
            },
            {
                path: 'sign-in',
                loadChildren: () =>
                    import('app/modules/auth/sign-in/sign-in.module').then(
                        (m) => m.AuthSignInModule
                    ),
            },
            {
                path: 'sign-up',
                loadChildren: () =>
                    import('app/modules/auth/sign-up/sign-up.module').then(
                        (m) => m.AuthSignUpModule
                    ),
            },
            {
                path: 'confirmation-required',
                loadChildren: () =>
                    import(
                        'app/modules/auth/confirmation-required/confirmation-required.module'
                    ).then((m) => m.AuthConfirmationRequiredModule),
            },
            {
                path: 'account-verified',
                loadChildren: () =>
                    import(
                        'app/modules/auth/account-verified/account-verified.module'
                    ).then((m) => m.AuthAccountVerifiedModule),
            },
            {
                path: 'auth-action',
                loadChildren: () =>
                    import('app/modules/auth/action/action.module').then(
                        (m) => m.AuthActionModule
                    ),
            },
        ],
    },

    // Auth routes for authenticated users
    {
        path: '',
        canActivate: [AngularFireAuthGuard],
        data: {
            authGuardPipe: redirectUnauthorizedOrUnverifiedUser,
            layout: 'empty',
        },
        component: LayoutComponent,
        children: [
            {
                path: 'sign-out',
                loadChildren: () =>
                    import('app/modules/auth/sign-out/sign-out.module').then(
                        (m) => m.AuthSignOutModule
                    ),
            },

            // { path: 'unlock-session', loadChildren: () => import('app/modules/auth/unlock-session/unlock-session.module').then(m => m.AuthUnlockSessionModule) }
        ],
    },
    // App routes
    {
        path: '',
        component: LayoutComponent,
        canActivate: [AngularFireAuthGuard],
        data: {
            authGuardPipe: redirectUnauthorizedOrUnverifiedUser,
            layout: 'compact',
        },
        resolve: {
            initialData: InitialDataResolver,
            subscription: SubscriptionResolver,
        },
        children: [
            {
                path: '',
                loadChildren: () =>
                    import('app/modules/client-view/client-view.module').then(
                        (m) => m.ClientViewModule
                    ),
            },
            {
                path: 'profile',
                canActivate: [IsUserAClientGuard],
                loadChildren: () =>
                    import(
                        'app/modules/client-profile-settings/client-profile-settings.module'
                    ).then((m) => m.ClientProfileSettingsModule),
            },
            {
                path: 'dashboard',
                canActivate: [IsUserACoachGuard],
                children: [
                    {
                        path: '',
                        loadChildren: () =>
                            import(
                                'app/modules/admin/dashboard/dashboard.module'
                            ).then((m) => m.DashboardModule),
                    },
                    // other dashboard routes here
                ],
            },
            {
                path: 'chat',
                loadChildren: () =>
                    import('app/modules/admin/apps/chat/chat.module').then(
                        (m) => m.ChatModule
                    ),
            },
            {
                path: 'forms',
                children: [
                    {
                        path: 'view',
                        loadChildren: () =>
                            import(
                                'app/modules/admin/forms/tab-view/tab-view.module'
                            ).then((m) => m.FormTabViewModule),
                    },

                    {
                        path: 'editor',
                        loadChildren: () =>
                            import(
                                'app/modules/components/form-builder/form-builder.module'
                            ).then((m) => m.FormBuilderModule),
                    },
                    {
                        path: 'submit',
                        loadChildren: () =>
                            import(
                                'app/modules/components/form-builder/form-builder.module'
                            ).then((m) => m.FormBuilderModule),
                    },
                    {
                        path: 'review',
                        loadChildren: () =>
                            import(
                                'app/modules/components/form-builder/form-builder.module'
                            ).then((m) => m.FormBuilderModule),
                    },
                    {
                        path: 'custom-analytics',
                        loadChildren: () =>
                            import(
                                'app/modules/custom-analytics/custom-analytics.module'
                            ).then((m) => m.CustomAnalyticsModule),
                    },
                ],
            },

            {
                path: 'settings',
                canActivate: [IsUserACoachGuard],
                children: [
                    {
                        path: '',
                        loadChildren: () =>
                            import(
                                'app/modules/admin/pages/settings/settings.module'
                            ).then((m) => m.SettingsModule),
                    },
                ],
            },
            {
                path: 'services',
                canActivate: [IsUserACoachGuard],
                children: [
                    {
                        path: '',
                        loadChildren: () =>
                            import('app/modules/services/services.module').then(
                                (m) => m.ServicesModule
                            ),
                    },
                ],
            },

            {
                path: 'clients',
                canActivate: [IsUserACoachGuard],
                children: [
                    {
                        path: '',
                        loadChildren: () =>
                            import('app/modules/clients/clients.module').then(
                                (m) => m.ClientsModule
                            ),
                    },
                ],
            },
            {
                path: 'checkins',
                children: [
                    {
                        path: '',
                        loadChildren: () =>
                            import('app/modules/checkins/checkins.module').then(
                                (m) => m.CheckinsModule
                            ),
                    },
                    {
                        path: 'submit',
                        loadChildren: () =>
                            import(
                                'app/modules/components/form-builder/form-builder.module'
                            ).then((m) => m.FormBuilderModule),
                    },
                    {
                        path: 'review',

                        loadChildren: () =>
                            import(
                                'app/modules/components/form-builder/form-builder.module'
                            ).then((m) => m.FormBuilderModule),
                    },
                ],
            },
            {
                path: 'todo',
                canActivate: [IsUserACoachGuard],
                children: [
                    {
                        path: '',
                        loadChildren: () =>
                            import(
                                'app/modules/admin/apps/scrumboard/scrumboard.module'
                            ).then((m) => m.ScrumboardModule),
                    },
                ],
            },
            {
                path: 'calendar',
                canActivate: [IsUserACoachGuard],
                children: [
                    {
                        path: '',
                        loadChildren: () =>
                            import('app/modules/calendar/calendar.module').then(
                                (m) => m.CalendarViewModule
                            ),
                    },
                ],
            },
            {
                path: 'plans',
                canActivate: [IsUserACoachGuard],
                children: [
                    {
                        path: '',
                        loadChildren: () =>
                            import('app/modules/plans/plans.module').then(
                                (m) => m.PlansModule
                            ),
                    },
                ],
            },
            {
                path: 'recipes',
                children: [
                    {
                        path: '',
                        loadChildren: () =>
                            import('app/modules/recipes/recipes.module').then(
                                (m) => m.RecipesModule
                            ),
                    },
                ],
            },
            {
                path: 'vault',
                children: [
                    {
                        path: '',
                        loadChildren: () =>
                            import(
                                'app/modules/document-vault/document-vault.module'
                            ).then((m) => m.DocumentVaultModule),
                    },
                ],
            },
            {
                path: 'workouts',
                children: [
                    {
                        path: '',
                        loadChildren: () =>
                            import(
                                'app/modules/exercise-log/exercise-log.module'
                            ).then((m) => m.ExerciseLogModule),
                    },
                ],
            },
            {
                path: 'help',
                loadChildren: () =>
                    import('app/modules/help-center/help-center.module').then(
                        (m) => m.HelpCenterModule
                    ),
            },
            {
                path: 'subscription',
                loadChildren: () =>
                    import(
                        './modules/admin/coachkit-subscription/coachkit-subscription.module'
                    ).then((m) => m.CoachKitSubscriptionModule),
            },
        ],
    },

    // Oauth routes
    {
        path: 'auth',
        component: EmptyLayoutComponent,
        loadChildren: () =>
            import('./modules/auth/oauth/oauth.module').then(
                (m) => m.AuthOauthModule
            ),
    },

    // Legal routes
    {
        path: 'legal',
        component: EmptyLayoutComponent,
        children: [
            {
                path: 'privacy',
                loadChildren: () =>
                    import(
                        'app/modules/public/legal/privacy/privacy.module'
                    ).then((m) => m.LegalPrivacyModule),
            },
            {
                path: 'terms',
                loadChildren: () =>
                    import('app/modules/public/legal/terms/terms.module').then(
                        (m) => m.LegalTermsModule
                    ),
            },
        ],
    },

    // TODO: Resolver to get data for logged in user and public user
    // TODO: Modal for playing back product tour
    // Help center routes (FAQs, Guides, Support)
    {
        path: 'public',
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'help',
                loadChildren: () =>
                    import('app/modules/help-center/help-center.module').then(
                        (m) => m.HelpCenterModule
                    ),
            },
        ],
    },

    /**
     * Download page
     */
    {
        path: 'download',
        component: LayoutComponent,
        data: { layout: 'empty' },
        loadChildren: () =>
            import('app/modules/download-page/download-page.module').then(
                (m) => m.DownloadPageModule
            ),
    },

    /**
     * Public forms
     */
    {
        path: 'public-form',
        component: LayoutComponent,
        data: { layout: 'empty' },
        loadChildren: () =>
            import(
                'app/modules/components/form-builder/form-builder.module'
            ).then((m) => m.FormBuilderModule),
    },

    /**
     * Redirect to dashboard for not found.
     */
    { path: '**', pathMatch: 'full', redirectTo: 'dashboard' },
];
