import { OnDestroy, Injectable } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable()
export abstract class SubscribeUntilDestroyedComponent implements OnDestroy {
    private readonly destroyed: Subject<void>;
    protected constructor() {
        this.destroyed = new Subject();
    }
    public subscribe(observable: Observable<any>): Subscription {
        return observable.pipe(takeUntil(this.destroyed)).subscribe();
    }
    public ngOnDestroy(): void {
        this.destroyed.next();
    }
}
