import { HelpWidgetLinks } from './help-widget.types';

export const helpCenterRoutes: HelpWidgetLinks[] = [
    {
        id: 'faqs',
        label: 'FAQs',
        description: 'Get answers to common questions',
        icon: 'mat_outline:help_center',
        link: '/help',
        useRouter: true,
    },
    {
        id: 'guides',
        label: 'Guides',
        description: 'Follow our tutorials on CoachKit features',
        icon: 'heroicons_outline:book-open',
        link: '/help/guides',
        useRouter: true,
    },
    {
        id: 'support',
        label: 'Support',
        description: 'Contact the team for support',
        icon: 'mat_outline:support',
        link: '/help/support',
        useRouter: true,
    },
    {
        id: 'support',
        label: 'Request a feature',
        description: 'Something you want to see in CoachKit? Let us know.',
        icon: 'mat_outline:thumb_up',
        link: '/help/feature',
        useRouter: false,
    },
];
